// src/paths.js
export const paths = [
  "aillant-sur-tholon",
  "aisne",
  "aix-en-othe",
  "ambleny",
  "amilly",
  "anet",
  "anizy-le-grand",
  "appoigny",
  "arcis-sur-aube",
  "ardennes",
  "argenteuil",
  "asnieres-sur-seine",
  "athies-sous-laon",
  "aube",
  "aubevoye",
  "aulnay-sous-bois",
  "auneau-bleury-saint-symphorien",
  "auxerre",
  "avallon",
  "ay-champagne",
  "bar-sur-aube",
  "bar-sur-seine",
  "barentin",
  "beaugency",
  "beauvais",
  "bernay",
  "betheny",
  "beuzeville",
  "bleneau",
  "bobigny",
  "bohain-en-vermandois",
  "bois-guillaume",
  "bolbec",
  "bonneval",
  "bouilly",
  "boulogne-billancourt",
  "bourg-achard",
  "bourgogne",
  "braine",
  "breteuil",
  "breuil-le-vert",
  "briare",
  "brienne-le-chateau",
  "brienon-sur-armancon",
  "brionne",
  "broglie",
  "brou",
  "bu",
  "canteleu",
  "caudebec-les-elbeuf",
  "centre",
  "cergy",
  "cernay-les-reims",
  "chablis",
  "chalette-sur-loing",
  "chambly",
  "champagne-ardenne",
  "champigny",
  "champigny-sur-marne",
  "champigny-sur-yonne",
  "chantilly",
  "charly-sur-marne",
  "charny-oree-de-puisaye",
  "chartres",
  "chateau-thierry",
  "chateaudun",
  "chateauneuf-en-thymerais",
  "chateauneuf-sur-loire",
  "chauny",
  "chavanges",
  "checy",
  "chelles",
  "cher",
  "chevannes",
  "choisy-au-bac",
  "chalons-en-champagne",
  "clermont",
  "cloyes-les-trois-rivieres",
  "compiegne",
  "conches-en-ouche",
  "conde-en-brie",
  "corbeil-essonnes",
  "cormontreuil",
  "cote-d-or",
  "courbevoie",
  "courson-les-carrieres",
  "courtenay",
  "courtisols",
  "courville-sur-eure",
  "crecy-sur-serre",
  "creil",
  "crepy-en-valois",
  "creteil",
  "damville",
  "darnetal",
  "deville-les-rouen",
  "dieppe",
  "dormans",
  "dreux",
  "elbeuf",
  "epernay",
  "epernon",
  "ervy-le-chatel",
  "essomes-sur-marne",
  "essonne",
  "estissac",
  "estrees-saint-denis",
  "etampes",
  "eu",
  "eure",
  "eure-et-loir",
  "evreux",
  "evry",
  "fagnieres",
  "fecamp",
  "fere-en-tardenois",
  "ferrieres-en-gatinais",
  "fismes",
  "fleury-les-aubrais",
  "folembray",
  "fontainebleau",
  "france",
  "fresnoy-le-grand",
  "gaillon",
  "gallardon",
  "garges-les-gonesse",
  "gauchy",
  "gien",
  "gisors",
  "gonfreville-l-orcher",
  "gouvieux",
  "grand-couronne",
  "grandvilliers",
  "gravigny",
  "guichainville",
  "guise",
  "gurgy",
  "harfleur",
  "haute-marne",
  "haute-normandie",
  "hauts-de-seine",
  "hery",
  "hirson",
  "hondouville",
  "illiers-combray",
  "indre",
  "indre-et-loire",
  "ingre",
  "ivry-sur-seine",
  "joigny",
  "la-chapelle-saint-luc",
  "la-chapelle-saint-mesmin",
  "la-loupe",
  "lacroix-saint-ouen",
  "laigneville",
  "lamorlaye",
  "laon",
  "le-grand-quevilly",
  "le-havre",
  "le-meux",
  "le-neubourg",
  "le-nouvion-en-thierache",
  "le-petit-quevilly",
  "le-vaudreuil",
  "les-andelys",
  "levallois-perret",
  "leves",
  "liancourt",
  "lillebonne",
  "localisez-vous",
  "loir-et-cher",
  "loiret",
  "loivre",
  "louviers",
  "luce",
  "luisant",
  "lusigny-sur-barse",
  "magenta",
  "mailly-le-camp",
  "maintenon",
  "mainvilliers",
  "malesherbes",
  "mantes-la-jolie",
  "margny-les-compiegne",
  "marigny-le-chatel",
  "marle",
  "marne",
  "maromme",
  "massy",
  "meaux",
  "melun",
  "meru",
  "mery-sur-seine",
  "mesnil-en-thelle",
  "meung-sur-loire",
  "migennes",
  "moneteau",
  "mont-saint-aignan",
  "montargis",
  "montargis",
  "montataire",
  "montivilliers",
  "montmirail",
  "montreuil",
  "mourmelon-le-grand",
  "muizon",
  "nanterre",
  "neuilly-en-thelle",
  "neuilly-saint-front",
  "neuville-aux-bois",
  "nievre",
  "nogent-l-artaud",
  "nogent-le-roi",
  "nogent-le-rotrou",
  "nogent-sur-eure",
  "nogent-sur-oise",
  "nogent-sur-seine",
  "noisy-le-grand",
  "noyon",
  "oise",
  "oissel",
  "olivet",
  "orleans",
  "ormoy-villers",
  "oulchy-le-chateau",
  "pacy-sur-eure",
  "palaiseau",
  "paris",
  "paris-75",
  "paron",
  "picardie",
  "piney",
  "pithiviers",
  "pitres",
  "poissy",
  "pont-audemer",
  "pont-de-larche",
  "pont-sainte-marie",
  "pont-sainte-maxence",
  "pont-sur-yonne",
  "pontoise",
  "provins",
  "rambouillet",
  "reims",
  "romilly-sur-seine",
  "rosieres-pres-troyes",
  "rouen",
  "saint-andre-de-leure",
  "saint-andre-les-vergers",
  "saint-aubin-les-elbeuf",
  "saint-aubin-les-elbeuf",
  "saint-brice-courcelles",
  "saint-cyr-en-val",
  "saint-denis",
  "saint-denis-en-val",
  "saint-etienne-du-rouvray",
  "saint-fargeau",
  "saint-florentin",
  "saint-georges-sur-baulche",
  "saint-germain",
  "saint-germain-en-laye",
  "saint-jacques-sur-darnetal",
  "saint-jean-de-braye",
  "saint-jean-de-la-ruelle",
  "saint-julien-les-villas",
  "saint-lubin-des-joncherets",
  "saint-lye",
  "saint-marcel",
  "saint-martin-d-ablois",
  "saint-maur-des-fosses",
  "saint-maximin",
  "saint-memmie",
  "saint-parres-aux-tertres",
  "saint-pierre-les-elbeuf",
  "saint-pryve-saint-mesmin",
  "saint-quentin",
  "saint-remy-sur-avre",
  "saint-sauveur-en-puisaye",
  "saint-sebastien-de-morsent",
  "saint-valery-en-caux",
  "sainte-menehould",
  "sainte-savine",
  "saone-et-loire",
  "saran",
  "sarcelles",
  "sarry",
  "seignelay",
  "seine-et-marne",
  "seine-maritime",
  "seine-saint-denis",
  "semoy",
  "senlis",
  "senonches",
  "sens",
  "soissons",
  "somme",
  "sotteville-les-rouen",
  "soulaines-dhuys",
  "suippes",
  "sully-sur-loire",
  "sezanne",
  "tergnier",
  "thiron-gardais",
  "thorigny-sur-oreuse",
  "tinqueux",
  "tonnerre",
  "toucy",
  "trainou",
  "troyes",
  "val-d-oise",
  "val-de-marne",
  "val-de-reuil",
  "vendeuvre-sur-barse",
  "vermenton",
  "verneuil-davre-et-diton",
  "verneuil-en-halatte",
  "vernon",
  "vernouillet",
  "verrieres",
  "versailles",
  "vertus",
  "vervins",
  "verzy",
  "ville-sous-la-ferte",
  "villemandeur",
  "villeneuve-la-guyard",
  "villeneuve-saint-germain",
  "villeneuve-sur-yonne",
  "villers-allerand",
  "villers-cotterets",
  "villers-saint-christophe",
  "villers-saint-paul",
  "vitry-le-francois",
  "vitry-sur-seine",
  "voves",
  "witry-les-reims",
  "yevres",
  "yonne",
  "yvelines",
  "yvetot"
];
