// src/core/libs/rect-toastify/tostProvider.ts
'use client';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import toastOptions from './ToastOptions';

interface ToastProviderProps {
  children: React.ReactNode;
}

export default function ToastProvider({ children }: ToastProviderProps) {
  return (
    <>
      {children}
      <ToastContainer {...toastOptions} />
    </>
  );
}
