// src/ui/client/input/NavButton.js
'use client';
import React, { useState, useEffect, useRef } from 'react';
import IntLink from '../../server/link/IntLink';
import useOutside from '@/hook/click/useOutside';
import { paths } from '../../../paths';
import styles from './NavButton.module.css';

const NavButton = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPaths, setFilteredPaths] = useState(paths);
  const [placeholder, setPlaceholder] = useState('Localisez-vous');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useOutside(dropdownRef, () => setShowDropdown(false));

  useEffect(() => {
    if (searchTerm) {
      const bestMatch = filteredPaths.length > 0 ? filteredPaths[0] : 'Localisez-vous';
      setPlaceholder(bestMatch);
    } else {
      setPlaceholder('Localisez-vous');
    }
  }, [searchTerm, filteredPaths]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = paths.filter((path) => path.toLowerCase().includes(term.toLowerCase()));
    setFilteredPaths(filtered);
    setShowDropdown(true);
  };

  return (
    <div className={styles.container}>
      <input ref={inputRef} value={searchTerm} onChange={handleSearch} placeholder={placeholder} className={styles.input} onFocus={() => setShowDropdown(true)} />
      {showDropdown && (
        <div ref={dropdownRef} className={styles.dropdown} role='listbox' id='dropdown-list'>
          {filteredPaths.map((path) => (
            <IntLink key={path} href={`/${path}`} ariaLabel={`Lien vers la page d'accueil de ${path}`}>
              <button className={styles.button} onClick={() => setShowDropdown(false)}>
                {path}
              </button>
            </IntLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavButton;
