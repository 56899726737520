// src/assets/img/Logo60.jsx
'use client';
import Image from 'next/image';
import useSelectImg from '@/hook/useSelectImg';

const Logo60 = ({ alt }) => {
  const logoPng = '/logo.png';
  const logoWebp = '/logo.webp';
  const altText = alt || "Logo de l'entreprise";

  const selectedLogo = useSelectImg(logoPng, logoWebp, undefined, logoPng);

  return <Image src={selectedLogo} alt={altText} width={60} height={60} priority />;
};

export default Logo60;
