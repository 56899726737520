// src/ui/server/link/FollowLink.tsx
import React from 'react';
import ExtLink, { ExtLinkProps } from './ExtLink';

interface FollowLinkProps extends Omit<ExtLinkProps, 'relAttributes'> {
  relAttributes?: string;
}

const FollowLink: React.FC<FollowLinkProps> = ({ href, children, newTab = false, ariaLabel = '', title = '', className, relAttributes = '' }) => {
  // Ajout de "follow" aux attributs rel existants
  const combinedRelAttributes = `follow ${relAttributes}`.trim();

  return (
    <ExtLink href={href} newTab={newTab} relAttributes={combinedRelAttributes} ariaLabel={ariaLabel} title={title} className={className}>
      {children}
    </ExtLink>
  );
};

export default React.memo(FollowLink);
