// src/core/hook/click/useOutside.js
'use client';
import { useEffect } from 'react';

const useOutside = (ref, onClose) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClose]);
};

export default useOutside;
// mettre les option pour les liseuse d'écran
