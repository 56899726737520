'use client';
import React from 'react';
import { usePathname } from 'next/navigation';
import IntLink from './IntLink';

type CityLinkProps = {
  href: string;
  children: React.ReactNode;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  locale?: string;
  className?: string;
  ariaLabel?: string;
};

const CityLink: React.FC<CityLinkProps> = ({ href, children, replace, scroll, shallow, locale, className, ariaLabel }) => {
  const pathname = usePathname();
  const cityMatch = pathname.split('/')[1] || 'localisez-vous';

  const buildPath = (path: string) => {
    if (cityMatch) {
      return `/${cityMatch}${path}`;
    } else {
      return path.startsWith('/') ? path : `/${path}`;
    }
  };

  return (
    <IntLink href={buildPath(href)} replace={replace} scroll={scroll} shallow={shallow} locale={locale} className={className} ariaLabel={ariaLabel}>
      {children}
    </IntLink>
  );
};

export default React.memo(CityLink);
