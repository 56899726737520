// src/ui/layout/Remounted.jsx
'use client';
import { useEffect, useState } from 'react';
import styles from './Remounted.module.css';

const Remounted = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Fonction optimisée pour vérifier la position de défilement
  const checkScrollBottom = (scrollPosition) => {
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;

    setIsVisible(scrollPosition > 0 && scrollPosition + windowHeight >= docHeight - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let last_known_scroll_position = 0;
    let ticking = false;
    const onScroll = () => {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          checkScrollBottom(last_known_scroll_position);
          ticking = false;
        });
        ticking = true;
      }
    };

    // Ajout de l'écouteur d'événement
    window.addEventListener('scroll', onScroll);

    // Nettoyage de l'écouteur d'événement pour éviter les fuites de mémoire
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button className={styles.button} onClick={scrollToTop} aria-label='Remonter en haut de la page'>
          ↑
        </button>
      )}
    </>
  );
};

export default Remounted;
