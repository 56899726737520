// src/core/config/siteConfig.ts
export const TITLE = 'Pro Rachat Auto';
export const DOMAIN = 'pro-rachat-auto.fr';
export const URL = `https://${DOMAIN}`;
// Location
// export const SEO_TITLE_TEMPLATE = `%s | ${NAME_URL}`;

export const LOCATIONS = {
  DEAGLE: {
    name: 'Deagle.dev',
    address: 'Deagle.dev, 50 boulevard Testaniere, 13010 Marseille',
    mapLink:
      'https://www.google.com/maps/dir/43.5368448,5.4463332/50+Bd+Testani%C3%A8re,+13010+Marseille/@43.4183102,5.3209939,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x12c9bf4fc57e8201:0xabf544460fbf3516!2m2!1d5.405204!2d43.2870751?entry=ttu',
  },
  KING: {
    name: 'KING PARBRISE',
    address: 'KING PARBRISE, Siege Social 3bd Méditerranéen, 13015 Marseille',
    mapLink: 'https://www.google.com/maps?q=3+Boulevard+Méditerranéen,+13015+Marseille',
  },
};

// mail
export const MAIL = {
  DEAGLE: 'contact@deagle.dev',
  RACHAT: 'contact@pro-rachat-auto.fr',
  ANTON: 'contact@antonyohan.com',
  KING: 'contact@kingparbrise.fr',
};
// Phone
export const PHONE = {
  DEAGLE: { name: 'Deagle', number: '+33698713978', formatted: '06 98 71 39 78' },
  RACHAT: { name: 'Rachat', number: '+33758061450', formatted: '07 58 06 14 50' },
  RACHAT1: { name: 'Rachat', number: '+33698713978', formatted: '06 98 71 39 78' },
  KING: { name: 'King', number: '+33650533435', formatted: '06 50 53 34 35' },
  KING1: { name: 'King1', number: '+33745950769', formatted: '07 45 95 07 69' },
  ANTON: { name: 'Anton', number: '+33753903869', formatted: '07 53 90 38 69' },
};
// Social
export const SOCIALS = {
  DEAGLE: [
    { name: 'Facebook', href: 'https://www.facebook.com/yewok.industrie/', icon: 'FacebookIcon' },
    { name: 'Instagram', href: 'https://www.instagram.com/deagle.dev/', icon: 'InstagramIcon' },
    { name: 'LinkedIn', href: 'https://linkedin.com/company/DeagleDev', icon: 'LinkedInIcon' },
    { name: 'YouTube', href: 'https://youtube.com/DeagleDev', icon: 'YouTubeIcon' },
    { name: 'WhatsApp', href: 'https://wa.me/33753903869', icon: 'WhatsAppIcon' },
  ],
  STAR: [
    { name: 'Facebook', href: 'https://www.facebook.com/star', icon: 'FacebookIcon' },
    { name: 'Instagram', href: 'https://www.instagram.com/star.dev/', icon: 'InstagramIcon' },
    { name: 'LinkedIn', href: 'https://linkedin.com/company/StarDev', icon: 'LinkedInIcon' },
    { name: 'YouTube', href: 'https://youtube.com/StarDev', icon: 'YouTubeIcon' },
  ],
};
