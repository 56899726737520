// src/ui/layout/footer/Foot.jsx
'use client';
import Link from 'next/link';
import { PATH } from '@/config/path';
import FollowLink from '@/server/link/FollowLink';
import { getCurrentYear } from '@/util/date/currentYear';
import styles from './Footer.module.css';
import dynamic from 'next/dynamic';

const CityLink = dynamic(() => import('@/server/link/CityLink'), {
  ssr: false,
});

const Foot = () => {
  const currentYear = getCurrentYear();

  return (
    <section className={styles.foot}>
      <div>
        2019-{currentYear} |
        <FollowLink href='https://deagle.dev' newTab={true} aria-label='Lien vers Deagle.dev studio de développement' className={styles.link}>
          Deagle.dev &reg;
        </FollowLink>
      </div>
      <ul className={styles.footerLegal}>
        <li>
          <CityLink href={PATH.SUPPORT} passHref>
            <span aria-label='Lien vers la page de support' className={styles.link}>
              Support
            </span>
          </CityLink>
        </li>
        <li>
          <CityLink href={PATH.TOS} passHref>
            <span aria-label='Lien vers la page de mentions légales' className={styles.link}>
              Mentions légales
            </span>
          </CityLink>
        </li>
      </ul>
    </section>
  );
};

export default Foot;
