'use client';

import { useEffect, useState } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookie-consent', 'rejected');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div style={bannerStyle}>
      <p>
        Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez notre utilisation des cookies.
      </p>
      <button onClick={handleAccept} style={buttonStyle}>
        Accepter
      </button>
      <button onClick={handleReject} style={buttonStyle}>
        Refuser
      </button>
    </div>
  );
};

const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'center',
};

const buttonStyle = {
  marginLeft: '10px',
  padding: '5px 10px',
};

export default CookieBanner;
