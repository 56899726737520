// src/core/hook/useDropdown.js
import { useState, useRef } from 'react';
import useOutside from './click/useOutside';

const useDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  let timeoutId = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useOutside(dropdownRef, closeDropdown);

  return {
    showDropdown,
    dropdownRef,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useDropdown;
