// src/core/hook/useSelectImg.ts
'use client';
import { useEffect, useState } from 'react';

type ImageFormat = string | undefined;

const defaultImg: ImageFormat = '/img/default-image.jpg'; // Assurez-vous que c'est un chemin valide
export const useSelectImg = (imgPng: ImageFormat, imgWebp: ImageFormat, imgJpg: ImageFormat): string => {
  // Commencez avec une image PNG par défaut qui est la même côté serveur et client
  const [imgSrc, setImgSrc] = useState<string>(imgPng || defaultImg);

  useEffect(() => {
    // Cette logique ne s'exécute que côté client
    function canUseWebP() {
      const elem = document.createElement('canvas');
      if (elem.getContext && elem.getContext('2d')) {
        // Testez la prise en charge de WebP
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      }
      return false;
    }

    // Si WebP est supporté, utilisez l'image WebP, sinon utilisez l'image JPG si elle est fournie
    if (canUseWebP() && imgWebp) {
      setImgSrc(imgWebp);
    } else if (imgJpg) {
      setImgSrc(imgJpg);
    }
    // S'il n'y a pas de WebP ni de JPG, l'image PNG par défaut sera utilisée
  }, [imgPng, imgWebp, imgJpg]);

  return imgSrc;
};

export default useSelectImg;
