// src/config/path/index.ts
export const PATH = {
  HOME: '/',
  INDEX: '/',
  CONTACT: '/contact',
  SERVICES: '/services',
  RACHATDV: '/services/rachats-de-voitures',
  ENLEVDV: '/services/enlevements-de-vehicules',
  DEPANEXP: '/services/depannage-express',
  JETERVV: '/services/jetez-votre-vehicule',
  SUPPORT: '/support',
  AUDIT: '/support/audit',
  WEBMASTER: '/support/webmaster',
  TOS: '/mentions-legales',
  TABLEM: '/table-des-matieres',
};
