'use client';
import { PATH } from '@/config/path';
import { forwardRef, lazy } from 'react';
import LazySpin from '@/util/LazySpin';
import useEscape from '@/hook/key/useEscape';
import Phone from '@/server/contact/Phone';
import styles from './Navbar.module.css';
import dynamic from 'next/dynamic';

const CityLink = dynamic(() => import('@/server/link/CityLink'), {
  ssr: false,
});

const paths = [
  { path: PATH.HOME, nav: 'Accueil' },
  { path: PATH.RACHATDV, nav: 'Rachats Voitures' },
  { path: PATH.ENLEVDV, nav: 'Enlevement Auto' },
  { path: PATH.DEPANEXP, nav: 'Depannage express' },
  { path: PATH.JETERVV, nav: 'Jeter vehicule' },
  { path: PATH.CONTACT, nav: 'Contact' },
  { path: PATH.SUPPORT, nav: 'Support' },
  { path: PATH.TABLEM, nav: 'Table des Matieres' },
];

const AsideNavMenu = forwardRef(({ isOpen, onClose }, ref) => {
  useEscape(onClose);

  return (
    <aside className={`${styles.aside} ${isOpen ? styles.asideOpen : ''}`} ref={ref} aria-label='Menu de navigation latéral'>
      <nav className={styles.linkContainer} role='navigation'>
        {paths.map(({ path, nav }) => (
          <CityLink href={path} key={nav}>
            <span className={styles.linkB}>{nav}</span>
          </CityLink>
        ))}
        {isOpen && (
          <LazySpin>
            <div className={styles.linkB} role='button'>
              <ul>
                <li>
                  <Phone phoneKey='RACHAT' />
                </li>
              </ul>
            </div>
          </LazySpin>
        )}
      </nav>
    </aside>
  );
});

AsideNavMenu.displayName = 'AsideNavMenu';
export default AsideNavMenu;
