// src/ui/server/link/ExtLink.tsx
import React, { ReactNode } from 'react';

export interface ExtLinkProps {
  href: string;
  children: ReactNode;
  newTab?: boolean;
  relAttributes?: string;
  ariaLabel?: string;
  title?: string;
  className?: string;
}

const ExtLink: React.FC<ExtLinkProps> = ({ href, children, newTab = false, relAttributes = '', ariaLabel = '', title = '', className }) => {
  const target = newTab ? '_blank' : '_self';
  const rel = newTab ? `noopener noreferrer ${relAttributes}`.trim() : relAttributes;

  return (
    <a href={href} target={target} rel={rel} aria-label={ariaLabel} title={title} className={className}>
      {children}
    </a>
  );
};

export default React.memo(ExtLink);
