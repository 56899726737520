// src/ui/server/link/IntLink.tsx
import React from 'react';
import Link from 'next/link';

type IntLinkProps = {
  href: string;
  as?: string;
  children: React.ReactNode;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  locale?: string;
  className?: string;
  ariaLabel?: string;
};

const isValidHref = (href: string): boolean => {
  const urlPattern = /^\/[a-zA-Z0-9-/]*$/;
  return urlPattern.test(href);
};

const IntLink: React.FC<IntLinkProps> = ({ href, as, children, replace, scroll, shallow, locale, className, ariaLabel }) => {
  if (!isValidHref(href)) {
    console.error(`Invalid href: ${href}`);
    return null;
  }

  return (
    <Link href={href} as={as} passHref={true} replace={replace} scroll={scroll} shallow={shallow} locale={locale}>
      <span className={className} aria-label={ariaLabel}>
        {children}
      </span>
    </Link>
  );
};

export default React.memo(IntLink);
