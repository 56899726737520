// src/core/hook/key/useEscape.js
'use client';
import { useEffect } from 'react';

const useEscape = (onClose) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);
};

export default useEscape;
// mettre les option pour les liseuse d'écran
